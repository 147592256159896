import React from 'react';
import { Card, CardContent, Typography, Box, Chip, LinearProgress } from '@mui/material';
import { Search as SearchIcon, Language as LanguageIcon } from '@mui/icons-material';

function KeywordProjectCard({ project }) {
  if (!project || !project.project_name) return null;

  return (
    <Card elevation={3} sx={{ height: '100%', borderRadius: 2, overflow: 'hidden' }}>
      <CardContent sx={{ p: 2 }}>
        <Typography variant="h6" noWrap sx={{ fontWeight: 'bold', mb: 2 }}>
          {project.project_name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Chip 
            icon={<SearchIcon />}
            label={`ID: ${project.project_id}`}
            color="primary"
            size="small"
            sx={{ borderRadius: 1 }}
          />
          <Chip
            label={project.report_saved ? "Report Created" : "Report Generating"}
            color="primary"
            variant={project.report_saved ? "filled" : "outlined"}
            size="small"
            sx={{ borderRadius: 1 }}
          />
        </Box>
        {project.domains && project.domains.map((domain, index) => (
          <Box key={index} sx={{ mb: 2, bgcolor: 'background.paper', p: 1.5, borderRadius: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LanguageIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body1" noWrap sx={{ fontWeight: 'medium' }}>{domain.name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body2" color="text.secondary">Rank 1 / Total:</Typography>
              <Typography variant="body2">{domain.gpage_1}/{domain.total}</Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={(domain.gpage_1 / domain.total) * 100} 
              sx={{ mt: 1, height: 6, borderRadius: 3 }}
            />
          </Box>
        ))}
        {(!project.domains || project.domains.length === 0) && (
          <Typography variant="body2">No domain data available</Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default KeywordProjectCard;