import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import SendIcon from '@mui/icons-material/Send';

function Sidebar({ currentPage, setCurrentPage }) {
  const menuItems = [
    { name: 'dashboard', icon: <DashboardIcon />, label: 'Dashboard' },
    { name: 'backtrack', icon: <HistoryIcon />, label: 'Backtrack' },
    { name: 'reports', icon: <AssessmentIcon />, label: 'Reports' },
    { name: 'tools', icon: <BuildIcon />, label: 'Tools' },
    { name: 'reportDrafts', icon: <DescriptionIcon />, label: 'Merge Sources' },
    { name: 'sendReports', icon: <SendIcon />, label: 'Send Reports' },
  ];

  return (
    <Box sx={{ width: 240, flexShrink: 0, borderRight: 1, borderColor: 'divider', height: '100vh' }}>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.name}
            selected={currentPage === item.name}
            onClick={() => {
              setCurrentPage(item.name);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Sidebar;