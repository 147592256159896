/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Box, Typography, List, Paper, Grid, TextField, Button, CircularProgress, IconButton, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { Assessment, Search, Business, ArrowForward, Delete, Web } from '@mui/icons-material';
import { apiBaseUrl } from '../config';
import DraggableListItem from './DraggableListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function ReportDrafts({ selectedReport }) {
  const [gaData, setGaData] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [businessListingsData, setBusinessListingsData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({
    ga: [],
    keywords: [],
    businessListings: []
  });
  const [clientName, setClientName] = useState('');
  const [connections, setConnections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isWebmasters, setIsWebmasters] = useState(false);

  useEffect(() => {
    if (selectedReport) {
      setIsLoading(true);
      fetchReportData(selectedReport.id);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (selectedReport) {
      fetchConnections();
    }
  }, [selectedReport]);

  const fetchConnections = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/connections`);
      if (!response.ok) {
        throw new Error('Failed to fetch connections');
      }
      const data = await response.json();
      console.log('Fetched connections:', data);
      setConnections(data);
      return data;
    } catch (error) {
      console.error('Error fetching connections:', error);
      return [];
    }
  };

  const filterAvailableData = (currentConnections, allData) => {
    console.log('Filtering available data');
    console.log('Current connections:', currentConnections);
    console.log('All data:', allData);

    const { googleAnalytics, keywords, businessListings } = allData;

    const filteredGa = googleAnalytics.filter(item => {
      const isAvailable = !currentConnections.some(conn => 
        conn.ga.some(gaItem => gaItem.property_id === item.property_id)
      );
      if (!isAvailable) {
        console.log('Filtered out GA item:', item);
      }
      return isAvailable;
    });

    const filteredKeywords = keywords.filter(item => {
      const isAvailable = !currentConnections.some(conn => 
        conn.keywords.some(kwItem => kwItem.project_id === item.project_id)
      );
      if (!isAvailable) {
        console.log('Filtered out Keyword item:', item);
      }
      return isAvailable;
    });

    const filteredBusinessListings = businessListings.filter(item => {
      const isAvailable = currentConnections.every(conn => 
        !conn.businessListings.some(blItem => blItem.name === item.name)
      );
      if (!isAvailable) {
        console.log('Filtered out Business Listing item:', item);
      }
      return isAvailable;
    });

    console.log('Filtered GA:', filteredGa);
    console.log('Filtered Keywords:', filteredKeywords);
    console.log('Filtered Business Listings:', filteredBusinessListings);

    setGaData(filteredGa);
    setKeywordsData(filteredKeywords);
    setBusinessListingsData(filteredBusinessListings);
  };

  const fetchReportData = async (reportId) => {
    try {
      // Fetch connections first
      const currentConnections = await fetchConnections();

      // Call validate-report before fetching the report data
      await fetch(`${apiBaseUrl}/api/validate-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ report: { id: reportId } }),
      });

      const response = await fetch(`${apiBaseUrl}/api/reports/${reportId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch report data');
      }
      const data = await response.json();
      console.log('Fetched report data:', data);
      
      // Use the freshly fetched connections
      filterAvailableData(currentConnections, data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemSelect = (type, item) => {
    setSelectedItems(prev => ({
      ...prev,
      [type]: prev[type].includes(item)
        ? prev[type].filter(i => i !== item)
        : [...prev[type], item]
    }));
  };

  const handleCreateConnection = async () => {
    if (clientName && (selectedItems.ga.length || selectedItems.keywords.length || selectedItems.businessListings.length)) {
      console.log('Selected GA items:', selectedItems.ga);
      console.log('Selected Keyword items:', selectedItems.keywords);
      console.log('Selected Business Listing items:', selectedItems.businessListings);

      const newConnection = {
        clientName,
        ga: selectedItems.ga,
        keywords: selectedItems.keywords,
        businessListings: selectedItems.businessListings,
        isWebmasters
      };
      console.log('Creating new connection:', newConnection);
      try {
        const response = await fetch(`${apiBaseUrl}/api/connections`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newConnection),
        });
        if (response.ok) {
          const savedConnection = await response.json();
          console.log('Saved connection:', savedConnection);
          // Fetch connections again to update the state
          await fetchConnections();
          setClientName('');
          setSelectedItems({ ga: [], keywords: [], businessListings: [] });
          setIsWebmasters(false);

          // Remove only the selected items from the lists
          setGaData(prevGaData => {
            console.log('Previous GA data:', prevGaData);
            console.log('Selected GA items:', selectedItems.ga);
            
            const newGaData = prevGaData.filter(item => {
              const shouldKeep = !selectedItems.ga.some(selected => {
                const match = selected.property_id === item.property_id;
                console.log(`Comparing ${selected.property_id} with ${item.property_id}: ${match}`);
                return match;
              });
              console.log(`Keeping item ${item.property_id}: ${shouldKeep}`);
              return shouldKeep;
            });
            
            console.log('Updated GA data:', newGaData);
            return newGaData;
          });
          setKeywordsData(prevKeywordsData => {
            const newKeywordsData = prevKeywordsData.filter(item => !selectedItems.keywords.some(selected => selected.project_id === item.project_id));
            console.log('Updated Keywords data:', newKeywordsData);
            return newKeywordsData;
          });
          setBusinessListingsData(prevBusinessListingsData => {
            const newBusinessListingsData = prevBusinessListingsData.filter(item => !selectedItems.businessListings.some(selected => selected.name === item.name));
            console.log('Updated Business Listings data:', newBusinessListingsData);
            return newBusinessListingsData;
          });
        } else {
          console.error('Error creating connection:', await response.text());
        }
      } catch (error) {
        console.error('Error creating connection:', error);
      }
    }
  };

  const handleRemoveConnection = async (connectionId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/connections/${connectionId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const removedConnection = connections.find(conn => conn.id === connectionId);
        console.log('Removed connection:', removedConnection);
        const updatedConnections = connections.filter(conn => conn.id !== connectionId);
        setConnections(updatedConnections);
        
        // Add removed items back to the lists
        setGaData(prevGaData => {
          const newGaData = [...prevGaData, ...removedConnection.ga];
          console.log('Updated GA data after removal:', newGaData);
          return newGaData;
        });
        setKeywordsData(prevKeywordsData => {
          const newKeywordsData = [...prevKeywordsData, ...removedConnection.keywords];
          console.log('Updated Keywords data after removal:', newKeywordsData);
          return newKeywordsData;
        });
        setBusinessListingsData(prevBusinessListingsData => {
          const newBusinessListingsData = [...prevBusinessListingsData, ...removedConnection.businessListings];
          console.log('Updated Business Listings data after removal:', newBusinessListingsData);
          return newBusinessListingsData;
        });
      } else {
        console.error('Error removing connection:', await response.text());
      }
    } catch (error) {
      console.error('Error removing connection:', error);
    }
  };

  const renderList = (title, data, type, icon) => (
    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>
        {icon} {title}
      </Typography>
      <List dense>
        {data.map((item, index) => (
          <DraggableListItem
            key={index}
            item={item}
            type={type}
            selected={selectedItems[type].includes(item)}
            onSelect={handleItemSelect}
            getItemPrimaryText={getItemPrimaryText}
            getItemSecondaryText={getItemSecondaryText}
          />
        ))}
      </List>
    </Paper>
  );

  const getItemPrimaryText = (type, item) => {
    switch (type) {
      case 'ga':
        return item.property_name || 'Unnamed Property';
      case 'keywords':
        return item.project_name || 'Unnamed Project';
      case 'businessListings':
        return item.title || 'Unnamed Business';
      default:
        return 'Unknown Item';
    }
  };

  const getItemSecondaryText = (type, item) => {
    switch (type) {
      case 'ga':
        return `Sessions: ${item.total_sessions}, Users: ${item.total_users}`;
      case 'keywords':
        const totalGPage1 = item.domain_stats
          ? Object.values(item.domain_stats).reduce((acc, stats) => acc + (stats.gpage_1 || 0), 0)
          : 0;
        const totalOverall = item.domain_stats
          ? Object.values(item.domain_stats).reduce((acc, stats) => acc + (stats.total || 0), 0)
          : 0;
        return `Project ID: ${item.project_id}, 1st Page: ${totalGPage1}/${totalOverall}`;
      case 'businessListings':
        const location = item.address?.locality ? `${item.address.locality}, ${item.address.administrativeArea}` : 'No location data';
        const status = item.verification_state?.hasBusinessAuthority ? 'Active' : 'Inactive';
        const calls = item.performance_data?.CALL_CLICKS || 0;
        const clicks = item.performance_data?.WEBSITE_CLICKS || 0;
        const views = item.performance_data?.VIEWS || 0;
        return (
          <>
            {`${location} - ${status}`}
            <br />
            {`Calls: ${calls}, Clicks: ${clicks}, Views: ${views}`}
          </>
        );
      default:
        return '';
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box>
        <Typography variant="h4" gutterBottom>Merge Sources</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {renderList('Google Analytics', gaData, 'ga', <Assessment color="primary" />)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderList('Keywords', keywordsData, 'keywords', <Search color="primary" />)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderList('Business Listings', businessListingsData, 'businessListings', <Business color="primary" />)}
          </Grid>
        </Grid>
        <Box mt={4} display="flex" alignItems="center">
          <TextField
            label="Client Name"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isWebmasters}
                onChange={(e) => setIsWebmasters(e.target.checked)}
              />
            }
            label="Webmasters"
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleCreateConnection}
            disabled={!clientName || (!selectedItems.ga.length && !selectedItems.keywords.length && !selectedItems.businessListings.length)}
            endIcon={<ArrowForward />}
          >
            Create Connection
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>Connections</Typography>
          <Grid container spacing={2}>
            {connections.map((connection, index) => (
              <Grid item xs={12} sm={6} md={4} key={connection.id}>
                <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveConnection(connection.id)}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  >
                    <Delete />
                  </IconButton>
                  <Typography variant="h6" gutterBottom>{connection.clientName}</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography><Assessment sx={{ mr: 1, verticalAlign: 'middle' }} /> GA: {connection.ga.length} items</Typography>
                  <Typography><Search sx={{ mr: 1, verticalAlign: 'middle' }} /> Keywords: {connection.keywords.length} items</Typography>
                  <Typography><Business sx={{ mr: 1, verticalAlign: 'middle' }} /> Business Listings: {connection.businessListings.length} items</Typography>
                  <Typography>
                    <Web sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Webmasters: {connection.isWebmasters ? 'Yes' : 'No'}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
}

export default ReportDrafts;