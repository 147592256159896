import React, { useState, useEffect } from 'react';
import { Button, Box, LinearProgress, } from '@mui/material';
import PropertyCard from './PropertyCard';
import { runGoogleAnalytics, resetGAState } from '../utils/analytics';
import { apiBaseUrl } from '../config';
import Pusher from 'pusher-js';

function GoogleAnalyticsReport({ setProperties, isLoading, onRunReport, selectedReport, gaAccounts }) {
  const [localProperties, setLocalProperties] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  console.log(progress);
  useEffect(() => {
    const pusher = new Pusher('f2da80e9773a761ef8ed', {
      cluster: 'us2',
    });
    const channel = pusher.subscribe('analytics_channel');
    channel.bind('property_data', data => {
      setLocalProperties(prev => {
        const existingIndex = prev.findIndex(p => p.property_id === data.property_id);
        if (existingIndex !== -1) {
          const updatedProperties = [...prev];
          updatedProperties[existingIndex] = { ...updatedProperties[existingIndex], ...data };
          return updatedProperties;
        } else {
          return [...prev, data];
        }
      });
      setProgress(prev => prev + 1);
    });

    return () => {
      pusher.unsubscribe('analytics_channel');
    };
  }, []);

  const handleRunReport = async (account) => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }

    setLocalProperties([]);
    setProgress(0);
    setIsProcessing(true);
    setCurrentAccount(account);
    onRunReport();

    try {
      if (!currentAccount) {
        await resetGAState();
      }
      await runGoogleAnalytics(selectedReport.id, account);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsProcessing(false);
      setCurrentAccount(null);
    }
  };

  const handleResetGAState = async () => {
    try {
      await resetGAState();
    } catch (error) {
      console.error('Error resetting GA state:', error);
      alert("Failed to reset GA state");
    }
  };

  const handleFinalizeReport = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/finalize-ga-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportId: selectedReport.id }),
      });

      if (!response.ok) {
        throw new Error('Failed to finalize report');
      }

      alert("Report finalized successfully");
    } catch (error) {
      console.error('Error finalizing report:', error);
      alert("Failed to finalize report");
    }
  };

  return (
    <Box>
      <Button onClick={handleResetGAState} disabled={isProcessing}>
        Reset GA State
      </Button>
      {gaAccounts.map((account, index) => (
        <Button 
          key={index} 
          onClick={() => handleRunReport(account)} 
          disabled={isProcessing || !selectedReport}
        >
          Run Google Analytics Report for {account}
        </Button>
      ))}
      <Button onClick={handleFinalizeReport} disabled={isProcessing || localProperties.length === 0}>
        Finalize Report
      </Button>
      {isProcessing && <LinearProgress />}
      {[...new Map(localProperties.map(item => [item.property_id, item])).values()].map(property => (
        <PropertyCard key={property.property_id} property={property} />
      ))}
    </Box>
  );
}

export default GoogleAnalyticsReport;