import { apiBaseUrl } from '../config';

export async function runKeywordsReport(reportId, setTotalPDFsToDownload, setKeywordProjects, setDownloadedPDFs) {
  try {
    let page = 1;
    let hasMore = true;
    let allProjectIds = [];

    while (hasMore) {
      const response = await fetch(`${apiBaseUrl}/api/process-projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportId, page, per_page: 7 }),
      });

      if (!response.ok) {
        throw new Error('Failed to start keywords report');
      }

      const data = await response.json();
      console.log('Keywords report progress:', data.message);

      setKeywordProjects(prev => [...prev, ...data.report_data]);
      allProjectIds = [...allProjectIds, ...data.report_data.map(project => project.project_id)];

      hasMore = data.has_more;
      page++;

      // Add a small delay to avoid overwhelming the server
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    setTotalPDFsToDownload(allProjectIds.length);

    // Download PDFs one by one
    for (const projectId of allProjectIds) {
      await downloadPDF(projectId, reportId, setKeywordProjects);
      setDownloadedPDFs(prev => prev + 1);
    }

    return allProjectIds;
  } catch (error) {
    console.error('Error in runKeywordsReport:', error);
    throw error;
  }
}

async function downloadPDF(projectId, reportId, setKeywordProjects) {
  try {
    const response = await fetch(`${apiBaseUrl}/api/download-pdfs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ project_ids: [projectId], report_id: reportId }),
    });
    if (!response.ok) {
      throw new Error('Failed to download PDF');
    }
    const data = await response.json();
    console.log('PDF downloaded:', data.results[0]);
    
    // Update keyword projects state
    setKeywordProjects(prev => prev.map(project => 
      project.project_id === projectId
        ? { ...project, status: 'completed', report_saved: true }
        : project
    ));
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
}