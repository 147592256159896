import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Fade } from '@mui/material';
import { Person, Timeline, CheckCircle } from '@mui/icons-material';
import useFadeIn from '../hooks/useFadeIn';

function PropertyCard({ property, index }) {
  const isVisible = useFadeIn(index);
  console.log(property)
  return (
    <Fade in={isVisible} timeout={300}>
      <Card>
        <CardContent>
          <Typography variant="h6">{property.property_name}</Typography>
          <Typography variant="body2">Property ID: {property.property_id}</Typography>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Chip 
                icon={<Person />}
                label={`Users: ${property.current_users}`}
                color="primary"
                sx={{ mr: 1 }}
              />
              <Chip 
                icon={<Timeline />}
                label={`Sessions: ${property.current_sessions}`}
                color="secondary"
              />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Chip 
                icon={<CheckCircle />}
                label="Processed PDF" 
                color="success"
                sx={{ mr: 1, mb: 1 }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Fade>
  );
}

export default PropertyCard;