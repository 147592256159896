import React, { useState } from 'react';
import { Typography, Grid, Card, CardContent, Box, Button, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Chip } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StarIcon from '@mui/icons-material/Star';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: theme.shadows[5],
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function Dashboard() {
  const [gaAccounts, setGaAccounts] = useState([
    '652498149784-bcprdti6ppb8lo2ii79imsq35pgppg5v.apps.googleusercontent.com',
    '436023334936-fsb6rg3b3nvtoicmfdmul0apumbi788a.apps.googleusercontent.com',
    '263075272838-e2lw5v56g2jqn7mlt5aiv803rnwpm9cu.apps.googleusercontent.com',
    '102741729756-k8d3okl5gyvda56p67whmgvjlj4ouul4.apps.googleusercontent.com',
    '162931315027-7rbb4rzmxyxtef2w1sg61j6fyz6kdqph.apps.googleusercontent.com',
    '598679738779-vphb5h05yvxjnu518of35iufngy5jo8x.apps.googleusercontent.com',
  ]);
  const [apiKeys, setApiKeys] = useState({
    semrush: '',
    ahrefs: '',
    moz: '',
    majestic: '',
    serpstat: '',
    keywordcom: '2b97e20245ddba8b0c1085b702d...',
  });

  const truncateAccount = (account) => {
    return account.substring(0, 50) + '...';
  };

  const handleGaUpload = (event) => {
    // TODO: Implement file upload logic
    const newAccount = '734750662537-0yf8cgz1hzhpxsx75vg1zvzrjkegj72k.apps.googleusercontent.com';
    setGaAccounts([...gaAccounts, newAccount]);
  };

  const handleBpUpload = (event) => {
    // TODO: Implement file upload logic
  };

  // const removeGaAccount = (account) => {
  //   setGaAccounts(gaAccounts.filter(acc => acc !== account));
  // };
  const removeGaAccount = () => {
    alert("no");
  };

  const handleApiKeyChange = (tool, value) => {
    setApiKeys(prev => ({ ...prev, [tool]: value }));
  };
  
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
        Setup Dashboard
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard elevation={2}>
            <CardContent sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" color="secondary.main" sx={{ fontWeight: 'bold' }}>Google Analytics</Typography>
                <Chip icon={<CheckCircleIcon />} label="Active" color="info" size="small" />
              </Box>
              <List sx={{ maxHeight: 80, overflowY: 'auto', mb: 1, '&::-webkit-scrollbar': { width: '0.4em' }, '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.1)', borderRadius: 4 } }}>
                {gaAccounts.map((account, index) => (
                  <ListItem key={index} sx={{ py: 0.5, px: 1, '&:hover': { backgroundColor: 'action.hover' }, backgroundColor: 'background.paper', borderRadius: 1, mb: 0.5 }}>
                    <ListItemText 
                      primary={truncateAccount(account)} 
                      sx={{ 
                        '& .MuiListItemText-primary': { 
                          fontSize: '0.8rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          paddingRight: '32px' // Add padding to prevent text from being covered by the delete icon
                        } 
                      }} 
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeGaAccount(account)} size="small">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Button
                component="label"
                variant="contained"
                startIcon={<AddIcon />}
                size="small"
                sx={{ mt: 1, width: '100%', py: 1, fontWeight: 'bold', textTransform: 'none' }}
              >
                Add GA Account
                <VisuallyHiddenInput type="file" onChange={handleGaUpload} />
              </Button>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard elevation={2}>
            <CardContent sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" color="secondary.main" sx={{ fontWeight: 'bold' }}>Business Profiles</Typography>
                <Chip icon={<CheckCircleIcon />} label="Active" color="info" size="small" />
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 2, 
                  color: 'text.secondary', 
                  backgroundColor: 'background.paper', 
                  p: 1, 
                  borderRadius: 1, 
                  fontSize: '0.8rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%'
                }}
              >
                Connected: {'987654321098-37wo0abhp4nzg8c3a7qw1ghtu64v.app.goog..'}
              </Typography>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                size="small"
                sx={{ py: 1 }}
                fullWidth
              >
                Change BP Account
                <VisuallyHiddenInput type="file" onChange={handleBpUpload} />
              </Button>
            </CardContent>
          </StyledCard>
        </Grid>
        {['semrush', 'keywordcom', 'ahrefs', 'moz', 'majestic', 'serpstat'].map((tool) => (
          <Grid item xs={12} sm={6} md={3} key={tool}>
            <StyledCard elevation={2}>
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" color="secondary.main" sx={{ fontWeight: 'bold' }}>{tool.charAt(0).toUpperCase() + tool.slice(1)}</Typography>
                  <Box>
                    <Chip
                      icon={apiKeys[tool] ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
                      label={apiKeys[tool] ? 'Active' : 'Inactive'}
                      color={apiKeys[tool] ? 'info' : 'default'}
                      size="small"
                    />
                    {tool === 'keywordcom' && (
                      <Chip
                        icon={<StarIcon />}
                        label="Priority"
                        color="secondary"
                        size="small"
                        sx={{ ml: 0.5 }}
                      />
                    )}
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  label="API Key"
                  variant="outlined"
                  value={apiKeys[tool]}
                  onChange={(e) => handleApiKeyChange(tool, e.target.value)}
                  placeholder={`Enter ${tool} API key`}
                  type={apiKeys[tool] ? 'password' : 'text'}
                  size="small"
                  sx={{ mt: 1, mb: 1 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    disabled={!apiKeys[tool]}
                    onClick={() => {/* TODO: Implement API key validation */}}
                    size="small"
                  >
                    Validate & Save
                  </Button>
                  <Button
                    variant="contained"
                    disabled={tool === 'keywordcom'}
                    onClick={() => {/* TODO: Implement set priority logic */}}
                    size="small"
                  >
                    Set Priority
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Dashboard;