import { useState, useEffect } from 'react';

const useFadeIn = (index, baseDelay = 50, maxDelay = 500) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const delay = Math.min(index * baseDelay, maxDelay);
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [index, baseDelay, maxDelay]);

  return isVisible;
};

export default useFadeIn;