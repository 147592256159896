import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, LinearProgress, Grid } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { runKeywordsReport } from '../utils/keywords';
import KeywordProjectCard from './KeywordProjectCard';
import { apiBaseUrl } from '../config';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const AnimatedGrid = styled(Grid)`
  animation: ${fadeIn} 0.5s ease-in;
  animation-fill-mode: backwards;
`;

function KeywordsReport({ selectedReport, isLoading, setIsLoading, setKeywordProjects, keywordProjects, setTotalPDFsToDownload, setDownloadedPDFs }) {
  const [visibleProjects, setVisibleProjects] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibleProjects(keywordProjects);
    }, 100);
    return () => clearTimeout(timer);
  }, [keywordProjects]);

  const handleRunKeywordsReport = async () => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }
    setIsLoading(true);
    setKeywordProjects([]);
    setTotalPDFsToDownload(0);
    setDownloadedPDFs(0);

    try {
      // Reset keyword data
      const resetResponse = await fetch(`${apiBaseUrl}/api/reset-keyword-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportId: selectedReport.id }),
      });

      if (!resetResponse.ok) {
        throw new Error('Failed to reset keyword data');
      }

      await runKeywordsReport(
        selectedReport.id,
        setTotalPDFsToDownload,
        setKeywordProjects,
        setDownloadedPDFs
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleRunKeywordsReport}
        disabled={isLoading || !selectedReport}
        startIcon={<SearchIcon />}
      >
        Run Keywords Report
      </Button>
      {!selectedReport && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          Please select a report before running the Keywords report.
        </Typography>
      )}
      {isLoading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
      <Typography variant="h6" sx={{ mt: 2 }}>Keyword Projects</Typography>
      <Grid container spacing={2}>
        {visibleProjects
          .sort((a, b) => (a.domains?.length || 0) - (b.domains?.length || 0))
          .map((project, index) => (
            <AnimatedGrid 
              item 
              xs={12} 
              sm={6} 
              md={3} 
              key={index} 
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <KeywordProjectCard project={project} />
            </AnimatedGrid>
          ))}
      </Grid>
    </>
  );
}

export default KeywordsReport;