import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloseIcon from '@mui/icons-material/Close';

function Header({ isLoggedIn, onLogout, selectedReport, onUnselectReport, currentPage }) {
  const theme = useTheme();

  const getPageTitle = () => {
    switch (currentPage) {
      case 'dashboard': return 'Dashboard';
      case 'reports': return 'Reports';
      case 'tools': return 'Tools';
      case 'settings': return 'Settings';
      default: return 'Dashboard';
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#0A1929' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RocketLaunchIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            {getPageTitle()}
          </Typography>
        </Box>
        {isLoggedIn && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedReport && (
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <AssessmentIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                <Typography variant="subtitle1">
                  {selectedReport.name}
                </Typography>
                <IconButton
                  size="small"
                  onClick={onUnselectReport}
                  sx={{ ml: 1, color: theme.palette.primary.main }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            <Button
              color="inherit"
              onClick={onLogout}
              startIcon={<LogoutIcon />}
              sx={{ color: theme.palette.primary.main }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;