/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button, Box, Tabs, Tab, Grid, Card, CardContent, Typography, LinearProgress, Chip, CardActions } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Dashboard as DashboardIcon, Assessment as AssessmentIcon, AccountCircle as AccountCircleIcon, Search as KeywordIcon, Business as BusinessIcon, Description, History as BacktrackIcon } from '@mui/icons-material';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import GoogleAnalyticsCallback from './components/GoogleAnalyticsCallback';
import LinkIcon from '@mui/icons-material/Link';
import GoogleAnalyticsReport from './components/GoogleAnalyticsReport';
import KeywordsReport from './components/KeywordsReport';
import BusinessListingsReport from './components/BusinessListingsReport';
import Login from './components/Login';
import { TabPanel, a11yProps } from './components/TabPanel';
import { setupPusher } from './utils/pusher';
import { apiBaseUrl } from './config';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DraftBuilder from './components/DraftBuilder';
import CssBaseline from '@mui/material/CssBaseline';
import { runKeywordsReport } from './utils/keywords';
import { runBusinessListingsReport } from './utils/businessListings';
import ReportManager from './components/ReportManager';
import ReportViewer from './components/ReportViewer';
import ReportsDrafts from './components/ReportsDrafts';
import Backtrack from './components/Backtrack';
import HistoryIcon from '@mui/icons-material/History';
import Sidebar from './components/Sidebar';
import Tools from './components/Tools';
import Settings from './components/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeywordProjectCard from './components/KeywordProjectCard';
import SendReports from './components/SendReports';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getGoogleAnalyticsAccounts, getGoogleAnalyticsAuthStatus, getGoogleAnalyticsAuthUrl, authenticateGoogleAnalytics } from './utils/analytics';
import RefreshIcon from '@mui/icons-material/Refresh';
import { resetGAState, runGoogleAnalytics, finalizeGAReport } from './utils/analytics';
import PropertyCard from './components/PropertyCard';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B0B0',
    },
    primary: {
      main: '#64B5F6',
    },
    secondary: {
      main: '#90CAF9',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(145deg, #121212 0%, #1E1E1E 100%)',
          minHeight: '100vh',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#2C2C2C',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          border: '1px solid #3A3A3A',
        },
      },
    },
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AuthSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    // Here you can update your app state to reflect successful authentication
    console.log('Authentication successful');
    // Redirect to main page after a short delay
    setTimeout(() => navigate('/'), 3000);
  }, [navigate]);

  return <div>Authentication successful! You will be redirected shortly.</div>;
}

function AuthError() {
  const query = useQuery();
  const errorMessage = query.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to main page after a short delay
    setTimeout(() => navigate('/'), 5000);
  }, [navigate]);

  return <div>Authentication failed: {errorMessage}. You will be redirected shortly.</div>;
}

function MainContent() {
  const [message, setMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [selectedReport, setSelectedReport] = useState(null);
  const [properties, setProperties] = useState([]);
  const [keywordProjects, setKeywordProjects] = useState([]);
  const [businessListings, setBusinessListings] = useState([]);
  const [isLoading, setIsLoading] = useState({
    googleAnalytics: false,
    keywords: false,
    businessListings: false,
    gaRefresh: false
  });
  const [progressInfo, setProgressInfo] = useState({
    googleAnalytics: { task: '', fraction: '', percentage: 0 },
    keywords: { downloadedPDFs: 0, totalPDFsToDownload: 0, currentTask: '' },
    businessListings: { progress: 0 }
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [totalPDFsToDownload, setTotalPDFsToDownload] = useState(0);
  const [downloadedPDFs, setDownloadedPDFs] = useState(0);
  const [connections, setConnections] = useState([]);
  const [gaAccounts, setGaAccounts] = useState([]);
  const [gaAuthStatus, setGaAuthStatus] = useState({});
  const [gaProperties, setGaProperties] = useState([]);
  const [processingAccount, setProcessingAccount] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      fetchData();
    }
    setupPusherListeners();
    fetchConnections();
    fetchGoogleAnalyticsAccounts();
  }, []);

  const fetchGoogleAnalyticsAccounts = async () => {
    try {
      const accounts = await getGoogleAnalyticsAccounts();
      console.log('Fetched GA accounts:', accounts);
      setGaAccounts(accounts);
      const status = await getGoogleAnalyticsAuthStatus();
      console.log('Fetched GA auth status:', status);
      setGaAuthStatus(status);
    } catch (error) {
      console.error('Error fetching Google Analytics accounts:', error);
    }
  };

  const handleGoogleAnalyticsAuth = async (account) => {
    try {
      const authUrl = await getGoogleAnalyticsAuthUrl(account);
      // Open the auth URL in a new window
      const authWindow = window.open(authUrl, '_blank', 'width=600,height=600');
      
      // Set up a message listener to receive the authentication result
      const handleMessage = async (event) => {
        if (event.data && event.data.type === 'GA_AUTH_RESULT') {
          window.removeEventListener('message', handleMessage);
          authWindow.close();
          
          if (event.data.success) {
            // Authentication was successful
            await fetchGoogleAnalyticsAccounts(); // Refresh the auth status
          } else {
            // Authentication failed
            console.error('Google Analytics authentication failed:', event.data.error);
            alert(`Error authenticating ${account}: ${event.data.error}`);
          }
        }
      };
      
      window.addEventListener('message', handleMessage);
    } catch (error) {
      console.error('Error initiating Google Analytics authentication:', error);
      alert(`Error initiating authentication for ${account}: ${error.message}`);
    }
  };
  const handleRunGoogleAnalytics = async (account) => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }
    
    setProcessingAccount(account);
    setIsLoading(prev => ({ ...prev, googleAnalytics: true }));
    
    try {
      if (!processingAccount) {
        await resetGAState(selectedReport.id);
      }
      await runGoogleAnalytics(selectedReport.id, account);
    } catch (error) {
      console.error('Error:', error);
      alert(`Error running Google Analytics report: ${error.message}`);
    } finally {
      setIsLoading(prev => ({ ...prev, googleAnalytics: false }));
      setProcessingAccount(null);
    }
  };

  const handleResetGAState = async () => {
    try {
      await resetGAState(selectedReport.id);
      setGaProperties([]);
    } catch (error) {
      console.error('Error resetting GA state:', error);
      alert("Failed to reset GA state");
    }
  };

  const handleFinalizeGAReport = async () => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }

    try {
      await finalizeGAReport(selectedReport.id);
      alert("Google Analytics report finalized successfully");
    } catch (error) {
      console.error('Error finalizing GA report:', error);
      alert("Failed to finalize Google Analytics report");
    }
  };

  const handleRunKeywordsReport = async () => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }
    setIsLoading(prev => ({ ...prev, keywords: true }));
    setKeywordProjects([]);
    setTotalPDFsToDownload(0);
    setDownloadedPDFs(0);
    try {
      await runKeywordsReport(
        selectedReport.id,
        setTotalPDFsToDownload,
        setKeywordProjects,
        setDownloadedPDFs
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, keywords: false }));
    }
  };

  const handleRunBusinessListingsReport = async () => {
    if (!selectedReport) {
      alert("Please select a report first.");
      return;
    }
    
    setIsLoading(prev => ({ ...prev, businessListings: true }));
    setBusinessListings([]);
    setProgressInfo(prev => ({ ...prev, businessListings: { progress: 0 } }));
    try {
      // Reset business listings for the report
      const resetUrl = new URL(`${apiBaseUrl}/api/reset-bl`);
      resetUrl.searchParams.append('reportId', selectedReport.id);
      const resetResponse = await fetch(resetUrl, {
        method: 'POST',
      });
      await resetResponse; // Wait for the reset to complete
      console.log('Business listings reset complete');

      // Check token validity
      const tokenCheckResponse = await fetch(`${apiBaseUrl}/api/check-token-bl`, {
        method: 'GET',
      });
      const tokenCheckData = await tokenCheckResponse.json();

      if (tokenCheckData.status === 'error') {
        console.log('Token has expired or been revoked. Redirecting for re-authentication.');

        // Open the auth URL in a new window/tab
        if (tokenCheckData.auth_url) {
          window.open(tokenCheckData.auth_url, '_blank');
          
          // Prompt the user to enter the code
          const authCode = prompt("Please enter the authorization code:");
          
          if (authCode) {
            // Send the auth code to the backend
            const authResponse = await fetch(`${apiBaseUrl}/api/auth-callback-bl`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ code: authCode }),
            });
            
            if (authResponse.ok) {
              console.log('Authorization successful');
              // Proceed with running the report
              await runBusinessListingsReport();
            } else {
              console.error('Authorization failed');
              alert('Authorization failed. Please try again.');
            }
          } else {
            console.log('User cancelled authentication');
            alert('Authentication cancelled. The report cannot be run without authorization.');
          }
        }
      } else {
        console.log('Token is valid, proceeding with business listings report');
        // Existing code to run the report...
        let pageToken = null;
        let completed = false;
        while (!completed) {
          const result = await runBusinessListingsReport(selectedReport.id, pageToken);
          if (result && Array.isArray(result.locations)) {
            const updatedLocations = result.locations.map(location => ({
              ...location,
              verification_state: location.verification_state || {},
              verification_status: location.verification_state?.hasVoiceOfMerchant ? 'Verified' : 'Not Verified',
              verification_reason: location.verification_reason || (() => {
                const state = location.verification_state;
                if (state?.complyWithGuidelines?.recommendationReason === 'BUSINESS_LOCATION_DISABLED') {
                  return { text: "Location disabled", color: "#FF6B6B" };
                } else if (state?.complyWithGuidelines?.recommendationReason === 'BUSINESS_LOCATION_SUSPENDED') {
                  return { text: "Location suspended", color: "#FF6B6B" };
                } else if (state?.complyWithGuidelines) {
                  return { text: `Guidelines non-compliance: ${state.complyWithGuidelines.recommendationReason}`, color: "#FFA500" };
                } else if (state?.resolveOwnershipConflict) {
                  return { text: "Ownership conflict", color: "#FFA500" };
                } else if (state?.verify?.hasPendingVerification === false) {
                  return { text: "Verification needed", color: "#4CAF50" };
                } else if (state?.verify?.hasPendingVerification === true) {
                  return { text: "Pending verification", color: "#FFA500" };
                } else {
                  return { text: "Unknown verification issue", color: "#808080" };
                }
              })()
            }));
            setBusinessListings(prev => [...prev, ...updatedLocations]);
            setProgressInfo(prev => ({ 
              ...prev, 
              businessListings: { 
                progress: prev.businessListings.progress + updatedLocations.length 
              } 
            }));
          } else {
            console.error('Unexpected result structure:', result);
          }
          pageToken = result?.next_page_token;
          completed = result?.completed || !pageToken;
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, businessListings: false }));
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/connections`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const connections = await response.json();
        setConnections(connections);
      } else {
        console.error('Failed to fetch connections');
      }
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  };

  const setupPusherListeners = () => {
    const pusher = setupPusher();
    const channel = pusher.subscribe('analytics_channel');

    channel.bind('progress', (data) => {
      setProgressInfo(prev => ({
        ...prev,
        googleAnalytics: { ...prev.googleAnalytics, ...data }
      }));
    });

    channel.bind('property_identified', (data) => {
      setProperties(prevProperties => [...prevProperties, { ...data, status: 'Identified' }]);
    });

    channel.bind('property_status', (data) => {
      setProperties(prevProperties => 
        prevProperties.map(prop => 
          prop.property_id === data.property_id ? { ...prop, status: data.status } : prop
        )
      );
    });

    channel.bind('property_data', (data) => {
      setProperties(prevProperties => 
        prevProperties.map(prop => 
          prop.property_id === data.property_id ? { ...prop, ...data } : prop
        )
      );
    });

    channel.bind('keyword_report_status', (data) => {
      if (data.status === 'report_saved') {
        setProgressInfo(prev => ({
          ...prev,
          keywords: {
            ...prev.keywords,
            downloadedPDFs: prev.keywords.downloadedPDFs + 1
          }
        }));
      } else if (data.status === 'completed') {
        setProgressInfo(prev => ({
          ...prev,
          keywords: {
            ...prev.keywords,
            totalPDFsToDownload: data.project_ids.length
          }
        }));
      }
    });

    channel.bind('keyword_project_update', (data) => {
      setKeywordProjects(prevProjects => {
        const existingProjectIndex = prevProjects.findIndex(p => p.project_id === data.project_id);
        if (existingProjectIndex !== -1) {
          const updatedProjects = [...prevProjects];
          updatedProjects[existingProjectIndex] = {
            ...updatedProjects[existingProjectIndex],
            ...data,
            domains: data.domains.map(domain => ({
              ...domain,
              rankOneFraction: `${domain.gpage_1}/${domain.total}`
            }))
          };
          return updatedProjects;
        } else {
          return [
            ...prevProjects,
            {
              ...data,
              status: 'pending',
              domains: data.domains.map(domain => ({
                ...domain,
                rankOneFraction: `${domain.gpage_1}/${domain.total}`
              }))
            }
          ];
        }
      });
    });

    channel.bind('property_data', data => {
      setGaProperties(prev => [...prev, data]);
    });

    return () => {
      pusher.unsubscribe('analytics_channel');
    };
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Sending request with token:', token);
      const response = await fetch(`${apiBaseUrl}/api/hello`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Response status:', response.status);
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        console.log('Unauthorized access, response:', await response.text());
        setIsLoggedIn(false);
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLogin = (token) => {
    console.log('Received token:', token);
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    fetchData();
  };

  const handleRefreshGAAccounts = async () => {
    try {
      setIsLoading(prev => ({ ...prev, gaRefresh: true }));
      await fetchGoogleAnalyticsAccounts();
    } catch (error) {
      console.error('Error refreshing Google Analytics accounts:', error);
      alert('Failed to refresh Google Analytics accounts. Please try again.');
    } finally {
      setIsLoading(prev => ({ ...prev, gaRefresh: false }));
    }
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard message={message} />;
      case 'reports':
        return (
          <ReportManager 
            onSelectReport={setSelectedReport} 
            selectedReport={selectedReport}
          />
        );
      case 'tools':
        return (
          <Box>
            <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} sx={{ mb: 2 }}>
              <Tab label="Google Analytics" />
              <Tab label="Keywords" />
              <Tab label="Business Listings" />
            </Tabs>
            {currentTab === 0 && (
              <>
                <Typography variant="h4" gutterBottom>Google Analytics Report</Typography>
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    Google Analytics Accounts
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshGAAccounts}
                      disabled={isLoading.gaRefresh}
                      sx={{ ml: 2 }}
                    >
                      {isLoading.gaRefresh ? 'Refreshing...' : 'Refresh Accounts'}
                    </Button>
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  onClick={handleResetGAState}
                  disabled={isLoading.googleAnalytics}
                  sx={{ mb: 2 }}
                >
                  Reset GA State
                </Button>
                <Grid container spacing={2}>
                  {gaAccounts.map((account, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={2} xl={1} key={account}>
                      <Card sx={{ 
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                        maxWidth: '150px',
                        margin: 'auto',
                      }}>
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <AccountCircleIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                            <Typography variant="body2" noWrap>
                              {account}
                            </Typography>
                          </Box>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                          {gaAuthStatus[account] ? (
                            <Button 
                              variant="contained" 
                              size="small" 
                              onClick={() => handleRunGoogleAnalytics(account)}
                              disabled={isLoading.googleAnalytics || !selectedReport}
                              startIcon={<AssessmentIcon />}
                            >
                              Run Report
                            </Button>
                          ) : (
                            <Button 
                              variant="outlined" 
                              size="small" 
                              onClick={() => handleGoogleAnalyticsAuth(account)}
                              startIcon={<LinkIcon />}
                              sx={{
                                color: '#1976D2',
                                borderColor: '#1976D2',
                                '&:hover': {
                                  backgroundColor: '#1976D2',
                                  color: theme.palette.common.white,
                                },
                              }}
                            >
                              Connect
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <Button 
                  variant="contained" 
                  onClick={handleFinalizeGAReport}
                  disabled={isLoading.googleAnalytics || !selectedReport || gaProperties.length === 0}
                  startIcon={<CheckCircleIcon />}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Finalize Google Analytics Report
                </Button>
                {isLoading.googleAnalytics && (
                  <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
                    <LinearProgress />
                  </Box>
                )}
                <Grid container spacing={3}>
                  {[...new Map(gaProperties.map(item => [item.property_id, item])).values()]
                    .sort((a, b) => b.current_users - a.current_users)
                    .map((property, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={property.property_id}>
                      <PropertyCard property={property} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {currentTab === 1 && (
              <KeywordsReport
              selectedReport={selectedReport}
              isLoading={isLoading.keywords}
              setIsLoading={(value) => setIsLoading(prev => ({ ...prev, keywords: value }))}
              setKeywordProjects={setKeywordProjects}
              keywordProjects={keywordProjects}
              onRunReport={handleRunKeywordsReport}
              setTotalPDFsToDownload={setTotalPDFsToDownload}
              setDownloadedPDFs={setDownloadedPDFs}
            />
            )}
            {currentTab === 2 && (
              <BusinessListingsReport
                selectedReport={selectedReport}
                isLoading={isLoading.businessListings}
                setIsLoading={(value) => setIsLoading(prev => ({ ...prev, businessListings: value }))}
                businessListings={businessListings}
                onRunReport={handleRunBusinessListingsReport}
                progressInfo={progressInfo.businessListings}
              />
            )}
          </Box>
        );
      case 'reportDrafts':
        return <ReportsDrafts selectedReport={selectedReport} />;
      case 'settings':
        return <Settings />;
      case 'backtrack':
        return <Backtrack selectedReport={selectedReport} />;
      case 'sendReports':
        return <SendReports selectedReport={selectedReport} connections={connections} />;
      default:
        return <Dashboard message={message} />;
    }
  };


  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="App">
      
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header 
          isLoggedIn={isLoggedIn} 
          onLogout={() => {
            setIsLoggedIn(false);
            localStorage.removeItem('token');
          }} 
          selectedReport={selectedReport}
          onUnselectReport={() => setSelectedReport(null)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <Box sx={{ display: 'flex' }}>
          <Sidebar currentPage={currentPage} setCurrentPage={setCurrentPage} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/auth-success" element={<AuthSuccess />} />
        <Route path="/auth-error" element={<AuthError />} />
        <Route path="/" element={<MainContent />} />
        <Route path="/api/ga-auth-callback" element={<GoogleAnalyticsCallback />} />
      </Routes>
    </Router>
  );
}

export default App;