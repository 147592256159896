import Pusher from 'pusher-js';

let pusherInstance = null;

export function setupPusher() {
  if (!pusherInstance) {
    pusherInstance = new Pusher('f2da80e9773a761ef8ed', {
      cluster: 'us2',
      encrypted: true
    });
  }
  return pusherInstance;
}