import React from 'react';
import { Box, Typography } from '@mui/material';

function Settings() {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>Settings</Typography>
    </Box>
  );
}

export default Settings;