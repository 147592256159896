import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Chip, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Business as BusinessIcon, Language as WebsiteIcon, Analytics as AnalyticsIcon, Visibility as ViewsIcon, Delete as DeleteIcon, Merge as MergeIcon, AttachFile as AttachFileIcon, Download as DownloadIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ReactDOMServer from 'react-dom/server';
import { apiBaseUrl } from '../config';

const DraggableChip = ({ icon: Icon, label, value, color = '#2196f3' }) => (
  <span contentEditable="false" style={{ display: 'inline-flex', alignItems: 'center', backgroundColor: color, color: 'white', padding: '2px 8px', borderRadius: '16px', margin: '0 4px', userSelect: 'none' }}>
    <Icon style={{ fontSize: '16px', marginRight: '4px' }} />
    <span>{label}: </span>
    <strong>{value}</strong>
  </span>
);

function SendReports({ selectedReport, connections: initialConnections }) {
  const [connections, setConnections] = useState(initialConnections || []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const emailContentRef = useRef(null);
  const [draftName, setDraftName] = useState('');
  const [savedDrafts, setSavedDrafts] = useState({});
  const [selectedChipsByRow, setSelectedChipsByRow] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [currentDraftId, setCurrentDraftId] = useState(null);

  useEffect(() => {
    if (!initialConnections || initialConnections.length === 0) {
      fetchConnections();
    }
  }, [initialConnections]);

  useEffect(() => {
    if (selectedConnection) {
      loadSavedDrafts(selectedConnection.id);
    }
  }, [selectedConnection]);

  const fetchConnections = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiBaseUrl}/api/connections`);
      if (!response.ok) {
        throw new Error('Failed to fetch connections');
      }
      const data = await response.json();
      setConnections(data);
    } catch (err) {
      console.error('Error fetching connections:', err);
      setError('Failed to load connections. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Send Reports</Typography>
        <Typography variant="body1">Loading connections...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Send Reports</Typography>
        <Typography variant="body1" color="error">{error}</Typography>
        <Button variant="contained" onClick={fetchConnections} sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  if (!connections || connections.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Send Reports</Typography>
        <Typography variant="body1">No connections available. Please add connections to continue.</Typography>
      </Box>
    );
  }

  const handleDragStart = (e, data) => {
    e.dataTransfer.setData('application/json', JSON.stringify(data));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const data = JSON.parse(e.dataTransfer.getData('application/json'));
      const emailContent = emailContentRef.current;
      
      // Create the chip element
      const chipElement = document.createElement('span');
      chipElement.contentEditable = 'false';
      chipElement.dataset.icon = data.icon;
      chipElement.dataset.label = data.label;
      chipElement.dataset.value = data.value;
      const IconComponent = {
        AnalyticsIcon,
        SearchIcon,
        BusinessIcon,
        ViewsIcon
      }[data.icon] || AnalyticsIcon;
      chipElement.innerHTML = ReactDOMServer.renderToString(
        <DraggableChip icon={IconComponent} label={data.label} value={data.value} />
      );

      // Insert the chip at the drop position or at the end if not focused
      const selection = window.getSelection();
      if (selection.rangeCount && emailContent.contains(selection.anchorNode)) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(chipElement);
        range.setStartAfter(chipElement);
        range.setEndAfter(chipElement);
        selection.removeAllRanges();
        selection.addRange(range);
        
        // Insert a space after the chip
        const space = document.createTextNode('\u00A0');
        range.insertNode(space);
        range.setStartAfter(space);
        range.setEndAfter(space);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        // If the textbox is not focused, append the chip at the end
        emailContent.appendChild(chipElement);
        emailContent.appendChild(document.createTextNode('\u00A0'));
        // Move cursor to the end
        const range = document.createRange();
        range.selectNodeContents(emailContent);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      // Focus the contentEditable div
      emailContent.focus();
    } catch (error) {
      console.error('Error parsing dragged data:', error);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleChipClick = (chipData, rowLabel) => {
    setSelectedChipsByRow(prevSelected => {
      const rowChips = prevSelected[rowLabel] || [];
      const isAlreadySelected = rowChips.some(chip => chip.id === chipData.id);

      if (isAlreadySelected) {
        return {
          ...prevSelected,
          [rowLabel]: rowChips.filter(chip => chip.id !== chipData.id)
        };
      } else {
        return {
          ...prevSelected,
          [rowLabel]: [...rowChips, chipData]
        };
      }
    });
  };

  const combineSelectedChips = (rowLabel) => {
    const selectedChips = selectedChipsByRow[rowLabel] || [];
    if (selectedChips.length < 1) return;

    const firstChip = selectedChips[0];
    let combinedNumerator = 0;
    let combinedDenominator = 0;

    selectedChips.forEach(chip => {
      if (typeof chip.value === 'string' && chip.value.includes('/')) {
        // Handle keyword ratio
        const [numerator, denominator] = chip.value.split('/').map(Number);
        combinedNumerator += numerator;
        combinedDenominator += denominator;
      } else {
        // Handle numeric values (Google Analytics and Business Listings)
        const numericValue = parseFloat(chip.value);
        if (!isNaN(numericValue)) {
          combinedNumerator += numericValue;
          combinedDenominator = 1; // Set to 1 for non-ratio values
        } else {
          console.warn(`Unexpected value format for chip: ${JSON.stringify(chip)}`);
        }
      }
    });

    // Format the combined value based on the chip type
    let formattedValue;
    if (rowLabel === 'keywords') {
      formattedValue = `${combinedNumerator}/${combinedDenominator}`;
    } else {
      formattedValue = Math.round(combinedNumerator).toString();
    }

    const combinedChip = {
      icon: firstChip.icon,
      label: `Combined ${firstChip.label}`,
      value: formattedValue
    };

    const emailContent = emailContentRef.current;
    const chipElement = document.createElement('span');
    chipElement.contentEditable = 'false';
    chipElement.dataset.icon = combinedChip.icon;
    chipElement.dataset.label = combinedChip.label;
    chipElement.dataset.value = combinedChip.value;
    const IconComponent = {
      AnalyticsIcon,
      SearchIcon,
      BusinessIcon,
      ViewsIcon
    }[combinedChip.icon] || AnalyticsIcon;
    chipElement.innerHTML = ReactDOMServer.renderToString(
      <DraggableChip icon={IconComponent} label={combinedChip.label} value={combinedChip.value} color="#228B22" />
    );

    const selection = window.getSelection();
    if (selection.rangeCount && emailContent.contains(selection.anchorNode)) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(chipElement);
      range.setStartAfter(chipElement);
      range.setEndAfter(chipElement);
      selection.removeAllRanges();
      selection.addRange(range);
      
      // Insert a space after the chip
      const space = document.createTextNode('\u00A0');
      range.insertNode(space);
      range.setStartAfter(space);
      range.setEndAfter(space);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      // If the textbox is not focused, append the chip at the end
      emailContent.appendChild(chipElement);
      emailContent.appendChild(document.createTextNode('\u00A0'));
      // Move cursor to the end
      const range = document.createRange();
      range.selectNodeContents(emailContent);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Focus the contentEditable div
    emailContent.focus();

    setSelectedChipsByRow(prevSelected => ({
      ...prevSelected,
      [rowLabel]: []
    }));
  };

  const renderDraggableCard = (item, icon, label, value, rowLabel) => {
    const chipId = `chip-${label}-${value}-${item.property_id || item.project_id || item.name}`;
    let chipValue;
    
    if (rowLabel === 'keywords') {
      chipValue = value; // Already in the correct format "x/y"
    } else if (rowLabel === 'ga') {
      chipValue = `${item.current_sessions}`; // Sessions/Users
    } else {
      chipValue = typeof value === 'number' ? value.toString() : value;
    }

    const isSelected = (selectedChipsByRow[rowLabel] || []).some(chip => chip.id === chipId);

    return (
      <Card
        id={chipId}
        elevation={3}
        sx={{ 
          height: '100px', // Set a fixed height
          cursor: 'pointer',
          border: isSelected ? '2px solid #2196f3' : 'none',
          transition: 'background-color 0.3s, border 0.3s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={() => handleChipClick({ id: chipId, icon: icon.type.name, label, value: chipValue }, rowLabel)}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, { id: chipId, icon: icon.type.name, label, value: chipValue })}
      >
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          <Typography variant="subtitle2" noWrap>{item.property_name || item.project_name || item.title}</Typography>
          <Chip
            icon={icon}
            label={`${label}: ${chipValue}`}
            color="primary"
            size="small"
            sx={{ mt: 1 }}
          />
        </CardContent>
      </Card>
    );
  };

  const renderDataSection = (title, icon, data, renderFunction, rowLabel) => (
    <Grid item xs={12} sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          {icon}
          {title}
        </Typography>
        <Button
          variant="contained"
          startIcon={<MergeIcon />}
          onClick={() => combineSelectedChips(rowLabel)}
          disabled={(selectedChipsByRow[rowLabel] || []).length < 1}
        >
          Combine Selected
        </Button>
      </Box>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {renderFunction(item, rowLabel)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const saveDraft = async () => {
    if (!draftName || !selectedConnection) {
      alert('Please enter a name for your draft and select a connection');
      return;
    }

    const draftContent = emailContentRef.current.innerHTML;
    const formData = new FormData();
    formData.append('name', draftName);
    formData.append('content', draftContent);
    formData.append('connectionId', selectedConnection.id);
    formData.append('reportId', selectedReport.id);

    attachments.forEach((file) => {
      formData.append('files[]', file);
    });

    try {
      const response = await fetch(`${apiBaseUrl}/api/drafts`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Draft saved:', result);
        loadSavedDrafts(selectedConnection.id);
        setDraftName('');
        setAttachments([]);
      } else {
        alert('Failed to save draft');
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Error saving draft');
    }
  };

  const loadSavedDrafts = async (connectionId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/drafts?connectionId=${connectionId}`);
      if (response.ok) {
        const drafts = await response.json();
        setSavedDrafts(prevDrafts => ({
          ...prevDrafts,
          [connectionId]: drafts
        }));
      } else {
        console.error('Failed to load drafts');
      }
    } catch (error) {
      console.error('Error loading drafts:', error);
    }
  };
  
const loadDraft = async (draftId) => {
  if (!selectedConnection) return;

  try {
    const response = await fetch(`${apiBaseUrl}/api/drafts/${draftId}?connectionId=${selectedConnection.id}`);
    if (response.ok) {
      const draft = await response.json();
      if (emailContentRef.current) {
        emailContentRef.current.innerHTML = draft.content;
      }
      setDraftName(draft.name);
      setCurrentDraftId(draftId);  // Set the current draft ID
      
      // Load attachments for the specific draft
      if (draft.attachments && draft.attachments.length > 0) {
        const loadedAttachments = draft.attachments.map(attachment => 
          new File([new Blob()], attachment.name, { type: attachment.type })
        );
        setAttachments(loadedAttachments);
      } else {
        setAttachments([]);
      }
    } else {
      alert('Failed to load draft');
    }
  } catch (error) {
    console.error('Error loading draft:', error);
    alert('Error loading draft');
  }
};

// Add this new function to clear the current draft
const clearCurrentDraft = () => {
  setCurrentDraftId(null);
  setDraftName('');
  setAttachments([]);
  if (emailContentRef.current) {
    emailContentRef.current.innerHTML = '';
  }
};

  const deleteDraft = async (draftId) => {
    if (!selectedConnection) return;
  
    try {
      const response = await fetch(`${apiBaseUrl}/api/drafts/${draftId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ connectionId: selectedConnection.id }),
      });
      if (response.ok) {
        loadSavedDrafts(selectedConnection.id);
      } else {
        alert('Failed to delete draft');
      }
    } catch (error) {
      console.error('Error deleting draft:', error);
      alert('Error deleting draft');
    }
  };

  const handleFileAttachment = (event) => {
    const files = Array.from(event.target.files);
    setAttachments(prevAttachments => [...prevAttachments, ...files]);
  };

  const removeAttachment = async (filename) => {
    if (!selectedConnection || !currentDraftId) return;

    try {
      const response = await fetch(`${apiBaseUrl}/api/drafts/${currentDraftId}/attachments/${filename}?connectionId=${selectedConnection.id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setAttachments(prevAttachments => prevAttachments.filter(file => file.name !== filename));
      } else {
        alert('Failed to remove attachment');
      }
    } catch (error) {
      console.error('Error removing attachment:', error);
      alert('Error removing attachment');
    }
  };

  const downloadAttachment = (filename) => {
    console.log('Downloading attachment:', filename, 'from draft ID:', currentDraftId);
    if (!selectedConnection || !currentDraftId) {
      console.error('Unable to download: Missing connection or draft ID');
      return;
    }
  
    const downloadUrl = `${apiBaseUrl}/api/drafts/${selectedConnection.id}/${currentDraftId}/${filename}`;
    window.open(downloadUrl, '_blank');
  };

  const downloadAllDrafts = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/drafts/download-all?connectionId=${selectedConnection.id}`, {
        method: 'GET',
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'all_drafts.eml';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        alert('Failed to download drafts');
      }
    } catch (error) {
      console.error('Error downloading drafts:', error);
      alert('Error downloading drafts');
    }
  };

  const renderConnectionCard = (connection) => {
    const isSelected = selectedConnection?.id === connection.id;
    const websiteCount = connection.businessListings.length;
    const gaCount = connection.ga.length;
    const keywordsCount = connection.keywords.length;

    return (
      <Card 
        key={connection.id}
        elevation={isSelected ? 3 : 1}
        sx={{ 
          mb: 1, 
          border: isSelected ? '2px solid #2196f3' : 'none',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: 3,
            cursor: 'pointer',
          }
        }}
        onClick={() => {
          setSelectedConnection(connection);
          clearCurrentDraft(); // Clear the current draft when changing connections
        }}
      >
        <CardContent sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" component="div" noWrap sx={{ flexGrow: 1 }}>
              {connection.clientName}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Chip
                icon={<WebsiteIcon sx={{ fontSize: '0.75rem' }} />}
                label={websiteCount}
                size="small"
                sx={{ height: 20, '& .MuiChip-label': { px: 0.5 } }}
              />
              <Chip
                icon={<AnalyticsIcon sx={{ fontSize: '0.75rem' }} />}
                label={gaCount}
                size="small"
                sx={{ height: 20, '& .MuiChip-label': { px: 0.5 } }}
              />
              <Chip
                icon={<SearchIcon sx={{ fontSize: '0.75rem' }} />}
                label={keywordsCount}
                size="small"
                sx={{ height: 20, '& .MuiChip-label': { px: 0.5 } }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Send Reports</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Connections</Typography>
          {connections.map((connection) => renderConnectionCard(connection))}
        </Grid>
        <Grid item xs={12} md={9}>
          {selectedConnection ? (
            <>
              <Typography variant="h5" gutterBottom>{selectedConnection.clientName}</Typography>
              {selectedConnection && savedDrafts[selectedConnection.id]?.length > 0 && (
                <Box sx={{ mb: 3, maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', p: 2 }}>
                  <Typography variant="h6" gutterBottom>Saved Drafts</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {savedDrafts[selectedConnection.id].map((draft) => (
                      <Chip
                        key={draft.id}
                        label={draft.name}
                        onClick={() => loadDraft(draft.id)}
                        onDelete={() => deleteDraft(draft.id)}
                        sx={{ maxWidth: '150px' }}
                      />
                    ))}
                  </Box>
                </Box>
              )}
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>Compose Email</Typography>
                <div
                  ref={emailContentRef}
                  contentEditable
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{
                    minHeight: '150px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '8px',
                    lineHeight: '1.5',
                  }}
                  onInput={(e) => {
                    // Prevent editing inside chips
                    const chips = e.currentTarget.querySelectorAll('span[contenteditable="false"]');
                    chips.forEach(chip => {
                      if (chip.innerHTML !== chip.textContent) {
                        const IconComponent = {
                          AnalyticsIcon,
                          SearchIcon,
                          BusinessIcon,
                          ViewsIcon
                        }[chip.dataset.icon] || AnalyticsIcon;
                        chip.innerHTML = ReactDOMServer.renderToString(
                          <DraggableChip
                            icon={IconComponent}
                            label={chip.dataset.label}
                            value={chip.dataset.value}
                          />
                        );
                      }
                    });
                  }}
                />
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <TextField
                    label="Draft Name"
                    value={draftName}
                    onChange={(e) => setDraftName(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 2, mb: 1 }}
                  />
                  <Button variant="contained" onClick={saveDraft} sx={{ mr: 2, mb: 1 }}>
                    Save Draft
                  </Button>
                  <Button variant="contained" sx={{ mr: 2, mb: 1 }}>Send Email</Button>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<AttachFileIcon />}
                    sx={{ mb: 1 }}
                  >
                    Attach Files
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileAttachment}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={downloadAllDrafts}
                    sx={{ mr: 2, mb: 1 }}
                  >
                    Download All Drafts
                  </Button>
                </Box>

                {attachments.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Attachments:</Typography>
                    <List>
                      {attachments.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} KB`} />
                          <ListItemSecondaryAction>
                            <IconButton 
                              edge="end" 
                              aria-label="download" 
                              onClick={() => downloadAttachment(file.name)} 
                              sx={{ mr: 1 }}
                            >
                              <DownloadIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => removeAttachment(file.name)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
              {selectedConnection.ga && renderDataSection(
                "Google Analytics",
                <AnalyticsIcon sx={{ verticalAlign: 'middle', mr: 1 }} />,
                [...new Map(selectedConnection.ga.map(item => [item.property_id, item])).values()],
                (item) => (
                  <>
                    {renderDraggableCard(item, <AnalyticsIcon />, "Sessions", item.current_sessions, "ga")}
                  </>
                ),
                "ga"
              )}
              {selectedConnection.keywords && renderDataSection(
                "Keywords",
                <SearchIcon sx={{ verticalAlign: 'middle', mr: 1 }} />,
                [...new Map(selectedConnection.keywords.map(item => [item.project_id, item])).values()],
                (item) => renderDraggableCard(item, <SearchIcon />, "Ranked 1st", `${item.domains[0].gpage_1}/${item.domains[0].total}`, "keywords"),
                "keywords"
              )}
              {selectedConnection.businessListings && renderDataSection(
                "Business Listings",
                <BusinessIcon sx={{ verticalAlign: 'middle', mr: 1 }} />,
                [...new Map(selectedConnection.businessListings.map(item => [item.name, item])).values()],
                (item) => renderDraggableCard(item, <ViewsIcon />, "Views", item.performance_data.VIEWS, "businessListings"),
                "businessListings"
              )}
            </>
          ) : (
            <Typography variant="body1">Select a connection to view details and compose an email</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SendReports;