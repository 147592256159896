import React from 'react';
import { ListItem, ListItemText, Checkbox } from '@mui/material';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../utils/ItemTypes';

function DraggableListItem({ item, type, selected, onSelect, getItemPrimaryText, getItemSecondaryText }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SOURCE,
    item: { ...item, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <ListItem
      ref={drag}
      sx={{ opacity: isDragging ? 0.5 : 1 }}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => onSelect(type, item)}
          checked={selected}
        />
      }
    >
      <ListItemText
        primary={getItemPrimaryText(type, item)}
        secondary={getItemSecondaryText(type, item)}
      />
    </ListItem>
  );
}

export default DraggableListItem;