import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, Grid, Card, CardContent, CardActions, Chip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import LanguageIcon from '@mui/icons-material/Language';
import BusinessIcon from '@mui/icons-material/Business';
import AnalyticsIcon from '@mui/icons-material/Analytics';


import { apiBaseUrl } from '../config';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-icon': {
    color: theme.palette.primary.contrastText,
  },
}));

function ReportManager({ onSelectReport, selectedReport }) {
  const [reports, setReports] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newReportName, setNewReportName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        fetchReports();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const fetchReports = async () => {
    console.log("Refreshing reports")
    setIsLoading(true);
    try {
      const response = await fetch(`${apiBaseUrl}/api/reports`);
      if (response.ok) {
        const data = await response.json();
        setReports(data);
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRecommendedReportName = () => {
    const date = new Date();
    const monthYear = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
    let counter = 1;
    const getNameWithSuffix = (base, count) => count === 1 ? base : `${base} (${count})`;

    const isNameTaken = (name) => reports.some(report => report.name === name);
    let recommendedName = monthYear;

    while (isNameTaken(recommendedName)) {
      counter++;
      recommendedName = getNameWithSuffix(monthYear, counter);
    }

    return recommendedName;
  };

  const handleCreateReport = async () => {
    const reportName = newReportName || getRecommendedReportName();
    try {
      const response = await fetch(`${apiBaseUrl}/api/reports`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ name: reportName }),
        credentials: 'include'
      });
      if (response.ok) {
        const newReport = await response.json();
        setReports([...reports, newReport]);
        setOpenDialog(false);
        setNewReportName('');
        onSelectReport(newReport);
      } else {
        console.error('Error creating report:', await response.text());
      }
    } catch (error) {
      console.error('Error creating report:', error);
    }
  };

  const handleSelectReport = (report) => {
    onSelectReport(report);
  };

  const handleRemoveReport = async (reportId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/reports/${reportId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (response.ok) {
        setReports(reports.filter(report => report.id !== reportId));
        onSelectReport(null);
      } else {
        console.error('Error removing report:', await response.text());
      }
    } catch (error) {
      console.error('Error removing report:', error);
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Reports</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Available Reports</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create New Report
        </Button>
      </Box>
      
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {reports.map((report) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={report.id}>
              <StyledCard
                sx={{
                  backgroundColor: selectedReport && selectedReport.id === report.id ? 'action.selected' : 'background.paper',
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {report.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    ID: {report.id}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    <StyledChip 
                      icon={<BusinessIcon />}
                      label={`Listings: ${report.businessListings?.length || 0}`}
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        '& .MuiChip-icon': {
                          color: 'primary.contrastText',
                        },
                      }}
                    />
                    <StyledChip 
                      icon={<AnalyticsIcon />}
                      label={`Analytics: ${report.googleAnalytics?.length || 0}`}
                      sx={{
                        backgroundColor: 'secondary.main',
                        color: 'secondary.contrastText',
                        '& .MuiChip-icon': {
                          color: 'secondary.contrastText',
                        },
                      }}
                    />
                    <StyledChip 
                      icon={<LanguageIcon />}
                      label={`Keywords: ${report.keywords?.length || 0}`}
                      sx={{
                        backgroundColor: 'info.main',
                        color: 'info.contrastText',
                        '& .MuiChip-icon': {
                          color: 'info.contrastText',
                        },
                      }}
                    />
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between', mt: 'auto', p: 2 }}>
                  <Button 
                    size="small" 
                    variant="outlined" 
                    onClick={() => handleSelectReport(report)}
                    sx={{
                      backgroundColor: selectedReport && selectedReport.id === report.id ? 'primary.main' : 'transparent',
                      color: selectedReport && selectedReport.id === report.id ? 'primary.contrastText' : 'primary.main',
                      '&:hover': {
                        backgroundColor: selectedReport && selectedReport.id === report.id ? 'primary.dark' : 'primary.light',
                      },
                    }}
                  >
                    {selectedReport && selectedReport.id === report.id ? 'Selected' : 'Select'}
                  </Button>
                  <IconButton size="small" color="error" onClick={() => handleRemoveReport(report.id)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
      
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Report</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Report Name"
            type="text"
            fullWidth
            value={newReportName}
            onChange={(e) => setNewReportName(e.target.value)}
            placeholder={getRecommendedReportName()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateReport} variant="contained">Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ReportManager;