import { apiBaseUrl } from '../config';

export async function runBusinessListingsReport(reportId, pageToken = null) {
  try {


    
    const url = new URL(`${apiBaseUrl}/api/business-listings`);
    url.searchParams.append('reportId', reportId);
    if (pageToken) {
      url.searchParams.append('pageToken', pageToken);
    }
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch business listings');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in runBusinessListingsReport:', error);
    throw error;
  }
}