import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticateGoogleAnalytics } from '../utils/analytics';

function GoogleAnalyticsCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');

      if (code) {
        try {
          await authenticateGoogleAnalytics('ga', code);
          // Redirect back to the main page or show a success message
          navigate('/');
        } catch (error) {
          console.error('Error authenticating:', error);
          // Handle error (show error message, redirect to error page, etc.)
        }
      }
    };

    handleCallback();
  }, [location, navigate]);

  return <div>Processing Google Analytics authentication...</div>;
}

export default GoogleAnalyticsCallback;