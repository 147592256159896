import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Button, Grid, Paper, MenuItem, Select, TextField } from '@mui/material';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { ItemTypes } from '../utils/ItemTypes';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { apiBaseUrl } from '../config'; // Assuming the config file is in the same directory level

// Function to fetch report data
const fetchReportData = async (reportId) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/reports/${reportId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch report data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching report data:', error);
    return null;
  }
};

function DraftBuilder({ selectedReport }) {
  const [connections, setConnections] = useState([]);
  const [draftContent, setDraftContent] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [operation, setOperation] = useState('add');
  const [emailContent, setEmailContent] = useState('');
  const [shortcodes, setShortcodes] = useState({});
  const emailRef = useRef(null);
  const cursorPositionRef = useRef(0);

  useEffect(() => {
    const savedConnections = localStorage.getItem('connections');
    if (savedConnections) {
      setConnections(JSON.parse(savedConnections));
    }
  }, []);

  useEffect(() => {
    if (connections.length > 0) {
      setSelectedConnection(connections[0]);
    }
  }, [connections]);

  const generateShortcodes = useCallback((data) => {
    console.log('Generating shortcodes for data:', data);
    if (!data || !selectedConnection) return;

    const newShortcodes = {};
    let gaIndex = 1;
    let keywordIndex = 1;
    let businessIndex = 1;

    // Generate shortcodes for Google Analytics data
    if (data.googleAnalytics && selectedConnection.ga) {
      data.googleAnalytics.forEach((ga) => {
        selectedConnection.ga.forEach(connGa => {
          if (connGa.property_id === ga.property_id) {
            console.log(`Match found for property ID: ${ga.property_id}`);
            newShortcodes[`ga${gaIndex}_sessions`] = ga.total_sessions;
            newShortcodes[`ga${gaIndex}_users`] = ga.total_users;
            gaIndex++;
          }
        });
      });
    }

    // Generate shortcodes for Keywords data
    if (data.keywords && selectedConnection.keywords) {
      data.keywords.forEach((keyword) => {
        selectedConnection.keywords.forEach(connKeyword => {
          if (connKeyword.project_id === keyword.project_id) {
            console.log(`Match found for keyword project ID: ${keyword.project_id}`);
            console.log(keyword);
            if (keyword.domains && keyword.domains.length > 0) {
              const totalDomains = keyword.domains.reduce((acc, domain) => {
                return {
                  total: acc.total + domain.total,
                  gpage_1: acc.gpage_1 + domain.gpage_1
                };
              }, { total: 0, gpage_1: 0 });
              newShortcodes[`keyword${keywordIndex}_gpage1`] = `${totalDomains.total}/${totalDomains.gpage_1}`;
              keywordIndex++;
            }
          }
        });
      });
    }

    // Generate shortcodes for Business Listings data
    if (data.businessListings && selectedConnection.businessListings) {
      data.businessListings.forEach((listing) => {
        selectedConnection.businessListings.forEach(connListing => {
          if (connListing.name === listing.name) {
            console.log(`Match found for business listing name: ${listing.name}`);
            newShortcodes[`business${businessIndex}_name`] = listing.title;
            newShortcodes[`business${businessIndex}_address`] = `${listing.address.addressLines.join(', ')}, ${listing.address.locality}, ${listing.address.administrativeArea}, ${listing.address.postalCode}, ${listing.address.regionCode}`;
            newShortcodes[`business${businessIndex}_phone`] = listing.phone;
            newShortcodes[`business${businessIndex}_callClicks`] = listing.performance_data.CALL_CLICKS;
            newShortcodes[`business${businessIndex}_websiteClicks`] = listing.performance_data.WEBSITE_CLICKS;
            newShortcodes[`business${businessIndex}_views`] = listing.performance_data.VIEWS;
            businessIndex++;
          }
        });
      });
    }

    setShortcodes(newShortcodes);
  }, [selectedConnection]);

  useEffect(() => {
    if (selectedConnection && selectedReport) {
      fetchReportData(selectedReport.id).then((data) => {
        generateShortcodes(data);
      });
    }
  }, [selectedConnection, selectedReport, generateShortcodes]);

  const handleConnectionSelect = (connection) => {
    setSelectedConnection(connection);
    setDraftContent([]);
  };

  const handleOperationChange = (event) => {
    setOperation(event.target.value);
  };

  const combineData = () => {
    if (draftContent.length < 2) return;

    let combinedValue;
    switch (operation) {
      case 'add':
        combinedValue = draftContent.reduce((acc, item) => acc + item.value, 0);
        break;
      case 'subtract':
        combinedValue = draftContent.reduce((acc, item) => acc - item.value);
        break;
      // Add more operations as needed
      default:
        combinedValue = 0;
    }

    setDraftContent([{ name: 'Combined Data', value: combinedValue }]);
  };

  const handleEmailChange = (event) => {
    cursorPositionRef.current = emailRef.current.selectionStart;
    setEmailContent(event.target.value);
  };

  const DroppableEmailContent = () => {
    const [{ isOver: isOverEmail }, dropEmail] = useDrop({
      accept: ItemTypes.CARD,
      drop: (item) => {
        const cursorPosition = emailRef.current.selectionStart;
        const newContent = `${emailContent.slice(0, cursorPosition)}{${item.label}}${emailContent.slice(cursorPosition)}`;
        setEmailContent(newContent);
        cursorPositionRef.current = cursorPosition + item.label.length + 2;
      },
      collect: (monitor) => ({
        isOverEmail: !!monitor.isOver(),
      }),
    });

    useEffect(() => {
      if (emailRef.current) {
        emailRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
        emailRef.current.focus();
      }
    }, []);

    return (
      <Box ref={dropEmail} sx={{ p: 2, border: '1px dashed gray', minHeight: '200px', mt: 2 }}>
        <TextField
          inputRef={emailRef}
          value={emailContent}
          onChange={handleEmailChange}
          variant="outlined"
          fullWidth
          multiline
          rows={6}
        />
        {isOverEmail && <Typography>Drop here!</Typography>}
      </Box>
    );
  };

  const replaceShortcodes = (content) => {
    return content.replace(/\{(\w+)\}/g, (match, p1) => shortcodes[p1] || match);
  };

  const saveDraft = () => {
    const draft = {
      connection: selectedConnection,
      content: draftContent,
      email: replaceShortcodes(emailContent),
    };
    // Save draft logic here (e.g., save to local storage or send to server)
    console.log('Draft saved:', draft);
  };

  const DraggableCard = ({ id, label }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { id, label },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    return (
      <Paper ref={drag} elevation={3} sx={{ p: 2, opacity: isDragging ? 0.5 : 1 }}>
        <Typography>{label}</Typography>
      </Paper>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Draft Builder (Empty if you didn't make any merged sources. TODO write an error for that)
        </Typography>
        <Grid container spacing={2}>
          {connections && connections.map((connection, index) => (
            <Grid item xs={12} key={index}>
              <Button variant="outlined" onClick={() => handleConnectionSelect(connection)}>
                {connection.clientName}
              </Button>
            </Grid>
          ))}
        </Grid>
        {selectedConnection && (
          <Box sx={{ p: 2, border: '1px dashed gray', minHeight: '400px', mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedConnection.clientName} Draft
            </Typography>
            <Grid container spacing={2}>
              {draftContent.map((content, index) => (
                <Grid item xs={12} key={index}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography>{content.name}: {content.value}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Select value={operation} onChange={handleOperationChange}>
                <MenuItem value="add">Add</MenuItem>
                <MenuItem value="subtract">Subtract</MenuItem>
                {/* Add more operations as needed */}
              </Select>
              <Button variant="contained" onClick={combineData} sx={{ ml: 2 }}>
                Combine Data
              </Button>
            </Box>
            <DroppableEmailContent />
            <Button variant="contained" onClick={saveDraft} sx={{ mt: 2 }}>
              Save Draft
            </Button>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Available Shortcodes
              </Typography>
              <Grid container spacing={2}>
                {Object.keys(shortcodes).map((key, index) => (
                  <Grid item xs={12} key={index}>
                    <DraggableCard id={key} label={key} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </DndProvider>
  );
}

export default DraftBuilder;