import React, { useMemo, useEffect, useState } from 'react';
import { Button, Typography, Box, LinearProgress, Grid, CircularProgress, Alert, Card, CardContent, Chip } from '@mui/material';
import { Business as BusinessIcon, Warning as WarningIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Visibility as VisibilityIcon, Phone as PhoneIcon, Language as LanguageIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AnimatedGrid = styled(Grid)`
  animation: ${fadeIn} 0.5s ease-out;
  animation-fill-mode: backwards;
`;

function BusinessListingsReport({ businessListings, isLoading, progressInfo, onRunReport, selectedReport }) {
  const [visibleListings, setVisibleListings] = useState([]);

  const sortedBusinessListings = useMemo(() => {
    return [...businessListings].sort((a, b) => {
      // First, sort by verification status
      if (a.verification_status === 'Verified' && b.verification_status !== 'Verified') return -1;
      if (a.verification_status !== 'Verified' && b.verification_status === 'Verified') return 1;
      
      // If verification status is the same, sort by views (descending order)
      const aViews = a.performance_data?.VIEWS || 0;
      const bViews = b.performance_data?.VIEWS || 0;
      return bViews - aViews;
    });
  }, [businessListings]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibleListings(sortedBusinessListings);
    }, 100);
    return () => clearTimeout(timer);
  }, [sortedBusinessListings]);

  return (
    <>
      <Alert severity="warning" icon={<WarningIcon />} sx={{ mb: 2 }}>
        Due to a pending quota limit upgrade request, the Business Listings report may take longer than usual to process.
      </Alert>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onRunReport}
          disabled={isLoading || !selectedReport}
          startIcon={<BusinessIcon />}
          sx={{ mr: 2 }}
        >
          Run Business Listings Report
        </Button>
        {isLoading && <CircularProgress size={24} />}
      </Box>
      {!selectedReport && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          Please select a report before running the Business Listings report.
        </Typography>
      )}
      {isLoading && progressInfo && progressInfo.businessListings && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <Typography variant="body2" gutterBottom>
            Fetching business listings... (This process may take some time due to current quota limitations)
          </Typography>
          <LinearProgress variant="determinate" value={progressInfo.businessListings.progress} />
        </Box>
      )}
      <Typography variant="h6" sx={{ mt: 2 }}>Business Listings</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Google My Business profiles processed: {businessListings.length}
      </Typography>
      <Grid container spacing={2}>
        {visibleListings.map((listing, index) => (
          <AnimatedGrid item xs={12} sm={6} md={2.4} key={index} style={{ animationDelay: `${index * 100}ms` }}>
            <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                  <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>{listing.title}</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Chip 
                      icon={listing.verification_state.hasVoiceOfMerchant ? <CheckCircleIcon /> : <ErrorIcon />}
                      label={listing.verification_state.hasVoiceOfMerchant ? "Verified" : "Unverified"}
                      color={listing.verification_state.hasVoiceOfMerchant ? "primary" : "default"}
                      size="small"
                    />
                    {!listing.verification_state.hasVoiceOfMerchant && listing.verification_reason && (
                      <Chip
                        label={listing.verification_reason}
                        color="default"
                        size="small"
                        sx={{ mt: 0.5 }}
                      />
                    )}
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>{listing.phone}</Typography>
                <Box sx={{ 
                  mt: 'auto',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  '& > *': { flexGrow: 1, minWidth: 'calc(33% - 4px)' }
                }}>
                  <Chip
                    icon={<VisibilityIcon fontSize="small" />}
                    label={`Views: ${listing.performance_data.VIEWS || 0}`}
                    size="small"
                    color="default"
                  />
                  <Chip
                    icon={<PhoneIcon fontSize="small" />}
                    label={`Calls: ${listing.performance_data.CALL_CLICKS || 0}`}
                    size="small"
                    color="default"
                  />
                  <Chip
                    icon={<LanguageIcon fontSize="small" />}
                    label={`Clicks: ${listing.performance_data.WEBSITE_CLICKS || 0}`}
                    size="small"
                    color="default"
                  />
                </Box>
              </CardContent>
            </Card>
          </AnimatedGrid>
        ))}
      </Grid>
    </>
  );
}

export default BusinessListingsReport;