import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, Avatar, CircularProgress } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { apiBaseUrl } from '../config';

const starAnimation = keyframes`
  0% { opacity: 0; transform: translateY(0); }
  50% { opacity: 1; }
  100% { opacity: 0; transform: translateY(-100px); }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(45deg, #f3f4f6 0%, #dfe3e8 100%)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  width: theme.spacing(7),
  height: theme.spacing(7),
  background: 'linear-gradient(45deg, #3f51b5 0%, #2196f3 100%)',
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: 8,
    },
    '&:hover fieldset': {
      borderColor: '#3f51b5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3f51b5',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiOutlinedInput-input': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

const StyledButton = styled(Button)({
  background: 'linear-gradient(45deg, #3f51b5 30%, #2196f3 90%)',
  border: 0,
  borderRadius: 8,
  boxShadow: '0 3px 5px 2px rgba(63, 81, 181, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  margin: '16px 0',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(45deg, #303f9f 30%, #1e88e5 90%)',
    transform: 'scale(1.05)',
    boxShadow: '0 6px 10px 4px rgba(63, 81, 181, .5)',
  },
});

const SpaceBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: 'linear-gradient(to bottom, #000000, #0f2027, #203a43)',
  zIndex: -2,
});

const StarField = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: -1,
});

const Star = styled(Box)(({ size, top, left, animationDuration }) => ({
  position: 'absolute',
  width: size,
  height: size,
  backgroundColor: 'white',
  borderRadius: '50%',
  top: `${top}%`,
  left: `${left}%`,
  boxShadow: '0 0 3px #ffffff',
  animation: `${starAnimation} ${animationDuration}s linear infinite`,
}));

const StatsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: '20px',
});

const StatItem = styled(Box)({
  textAlign: 'center',
});

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [stars, setStars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newStars = Array.from({ length: 50 }, () => ({
      size: Math.random() * 3 + 1,
      top: Math.random() * 100,
      left: Math.random() * 100,
      animationDuration: Math.random() * 3 + 2,
    }));
    setStars(newStars);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch(`${apiBaseUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.access_token);
        onLogin(data.access_token);
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 10, position: 'relative' }}>
      <SpaceBackground />
      <StarField>
        {stars.map((star, index) => (
          <Star key={index} {...star} />
        ))}
      </StarField>
      <StyledPaper elevation={6}>
        <StyledAvatar>
          <RocketLaunchIcon />
        </StyledAvatar>
        <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
          QuickReports
        </Typography>
        <form onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
          />
          <StyledTextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <StyledButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" sx={{ color: 'white' }} /> : 'Login'}
          </StyledButton>
        </form>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <StatsContainer sx={{ display: 'flex', justifyContent: 'space-between', mt: 0, backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '8px', padding: '16px' }}>
          <StatItem sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold', textShadow: '0 0 5px rgba(63, 81, 181, 0.3)' }}>
              2+
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Agencies</Typography>
          </StatItem>
          <StatItem sx={{ flex: 1, textAlign: 'center', borderLeft: '1px solid rgba(255, 255, 255, 0.1)', borderRight: '1px solid rgba(255, 255, 255, 0.1)' }}>
            <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold', textShadow: '0 0 5px rgba(63, 81, 181, 0.3)' }}>
              183+
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Businesses Managed</Typography>
          </StatItem>
          <StatItem sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold', textShadow: '0 0 5px rgba(63, 81, 181, 0.3)' }}>
              6+
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Reports Generated</Typography>
          </StatItem>
        </StatsContainer>
      </StyledPaper>
    </Box>
  );
}

export default Login;