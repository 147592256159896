import { apiBaseUrl } from '../config';

export async function getGoogleAnalyticsAccounts() {
  const response = await fetch(`${apiBaseUrl}/api/ga-accounts`);
  if (!response.ok) {
    throw new Error('Failed to get Google Analytics accounts');
  }
  return await response.json();
}

export async function getGoogleAnalyticsAuthStatus() {
  const response = await fetch(`${apiBaseUrl}/api/ga-auth-status`);
  if (!response.ok) {
    throw new Error('Failed to get Google Analytics auth status');
  }
  return await response.json();
}

export async function getGoogleAnalyticsAuthUrl(account) {
  const response = await fetch(`${apiBaseUrl}/api/ga-auth-url?account=${account}`);
  if (!response.ok) {
    throw new Error('Failed to get Google Analytics auth URL');
  }
  const data = await response.json();
  return data.auth_url;
}

export async function authenticateGoogleAnalytics(account, authCode) {
  const response = await fetch(`${apiBaseUrl}/api/ga-auth-callback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ account, code: authCode }),
  });

  if (!response.ok) {
    throw new Error('Failed to authenticate Google Analytics');
  }

  return await response.json();
}

export async function resetGAState(reportId) {
  await fetch(`${apiBaseUrl}/api/reset-ga-state`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reportId }),
  });
}

export async function finalizeGAReport(reportId) {
  await fetch(`${apiBaseUrl}/api/finalize-ga-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reportId }),
  });
}

export async function runGoogleAnalytics(reportId, account) {
  const response = await fetch(`${apiBaseUrl}/api/run-google-analytics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reportId, account }),
  });

  if (!response.ok) {
    throw new Error('Failed to run Google Analytics report');
  }

  return await response.json();
}